import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Image from "next/image";
import { useRouter } from "next/router";
import { useCookie } from "next-cookie";
import { GET_STATE_USER } from "../../action/packages";
import { useQuery } from "@apollo/client";

const ModalAds = (props) => {
  const router = useRouter();
  const cookie = useCookie();
  const isLogin = cookie.get("token");
  const lat = cookie.get("lat");
  const lng = cookie.get("lng");
  const banner = cookie.get("bn_sts");
  const packageBanner = cookie.get("bn_pkg_sts");
  const hideBanner = cookie.get("bn_hd_sts");
  const currentDate = new Date().setUTCHours(0, 0, 0, 0);
  const limitDate = new Date("2024-08-31").getTime();
  const tomorrowDate = new Date(currentDate + 86400000);

  const [openBanner, setOpenBanner] = useState({
    promo: true,
  });

  const { data } = useQuery(GET_STATE_USER, {
    variables: {
      lat: lat || "",
      lng: lng || "",
    },
  });

  const allowedState = [11, 12, 13, 16, 20];
  const stateUser = data?.state.state_by_lat_lng;

  useEffect(() => {
    if (
      (banner || packageBanner) === undefined &&
      stateUser &&
      isLogin !== undefined
    ) {
      let handleDelay = setInterval(() => {
        setOpenBanner((prevState) => ({
          ...prevState,
          promo: allowedState.includes(stateUser.state_id),
        }));
        clearInterval(handleDelay);
      }, 2000);
    }
  }, [stateUser]);

  // Check which modal should be displayed based on path and cookie
  useEffect(() => {
    if (router.pathname === "/" && banner === "2") {
      setOpenBanner((prevState) => ({
        ...prevState,
        promo: false,
      }));
    } else if (router.pathname === "/packages" && packageBanner === "2") {
      setOpenBanner((prevState) => ({
        ...prevState,
        promo: false,
      }));
    }
  }, [router.pathname, banner, packageBanner]);

  const handleCloseModalBannerPromo = () => {
    setOpenBanner({ promo: false });

    if (router.pathname === "/packages") {
      cookie.set("bn_pkg_sts", "2", {
        expires: tomorrowDate,
      });
    } else {
      cookie.set("bn_sts", "2", {
        expires: tomorrowDate,
      });
    }
  };

  const handleRedirectToPackage = () => {
    if (router.pathname === "/") {
      router.push(`/packages`);
    } else {
      setOpenBanner({ promo: false });
      cookie.set("bn_pkg_sts", "2", {
        expires: tomorrowDate,
      });
    }
  };

  return (
    <>
      {currentDate < limitDate && (
        <Modal
          contentClassName="rounded-3 border-0 bg-transparent position-relative"
          size="xl"
          show={openBanner.promo}
          centered
          backdrop="static"
          onHide={handleCloseModalBannerPromo}
        >
          <button
            type="button"
            className="d-none d-lg-block btn bg-white fs-2 position-absolute px-2 py-0"
            style={{ top: -15, right: -15, zIndex: 99 }}
            onClick={handleCloseModalBannerPromo}
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <div
            className="cursor-pointer d-none d-lg-block"
            onClick={handleRedirectToPackage}
            style={{
              width: "calc(100% - 8.75rem)",
              height: "29rem",
            }}
          >
            <Image
              src="/assets/promo-17agt-2024.png"
              alt="promo 17 agustus"
              layout="fill"
              objectFit="cover"
              placeholder="blur"
              blurDataURL="/assets/promo-17agt-2024.png"
            />
          </div>

          <div
            className="mx-auto d-flex d-lg-none flex-column align-items-center justify-content-between gap-2"
            style={{
              width: "17.75rem",
              height: "37.75rem",
            }}
          >
            <div
              className="cursor-pointer position-relative"
              style={{
                width: "100%",
                height: "30.25rem",
              }}
              onClick={handleRedirectToPackage}
            >
              <Image
                src="/assets/promo-17agt-2024-mobile.png"
                alt="promo 17 agustus"
                layout="fill"
                objectFit="contain"
                placeholder="blur"
                blurDataURL="/assets/promo-17agt-2024.png"
              />
            </div>

            <button
              type="button"
              className="btn bg-white fs-2"
              onClick={handleCloseModalBannerPromo}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </Modal>
      )}

      {/* {openBanner.promoTahunbaru && currentDate < limitDate && (
        <div
          className="bg-white flex-grow-1 position-sticky bottom-0 left-50 w-100"
          style={{ zIndex: 1031 }}
        >
          <div className="d-block d-md-none container text-center cursor-pointer position-relative">
            <div
              className="text-white fs-2 position-absolute p-0"
              style={{ top: 0, right: 20, zIndex: 1032 }}
              onClick={() => {
                setOpenBanner({ ...openBanner, promoTahunbaru: false });
                cookie.set("bn_hd_sts", false);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </div>
            <div
              className="position-relative"
              onClick={handleRedirectToPackage}
            >
              <Image
                src="/assets/banner-tahun-baru-mobile.png"
                alt="banner tahun baru mobile"
                width={670}
                height={220}
              />
            </div>
          </div>

          <div className="d-none d-md-block container text-center cursor-pointer position-relative">
            <div
              className="text-white fs-2 position-absolute p-0"
              style={{ top: 0, right: 20, zIndex: 1032 }}
              onClick={() => {
                setOpenBanner({ ...openBanner, promoTahunbaru: false });
                cookie.set("bn_hd_sts", false);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </div>
            <div
              className="position-relative"
              onClick={handleRedirectToPackage}
            >
              <Image
                src="/assets/banner-tahun-baru.png"
                alt="banner tahun baru"
                width={1303}
                height={250}
              />
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default ModalAds;
