import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import Link from "next/link";
import React from "react";

const FooterAppAds = () => {
  const { t } = useTranslation("common");

  const title = t("banner_download.title");
  const description = t("banner_download.description");
  const webDownload =
    "https://play.google.com/store/apps/details?id=com.rumahdewiagent.app";

  const appDownload =
    "https://apps.apple.com/id/app/rumahdewi-agent/id6572291089?l=id";
  return (
    // <div className="footer-bg-img flex-grow-1 position-sticky bottom-0">
    <>
      <div
        className="d-block d-md-none position-relative"
        style={{ height: "828px" }}
      >
        <Image
          src="/assets/ads-footer-new-background-mobile.png"
          objectFit="contain"
          layout="fill"
        />

        <div className="position-relative container d-flex flex-column">
          <div
            className="position-relative flex-grow-1"
            style={{ height: "320px", width: "300px", left: 35, top: 80 }}
          >
            <Image
              src="/assets/ads-footer-phone-background.png"
              layout="fill"
            />
          </div>

          <div
            className="position-relative d-flex flex-column justify-content-between gap-2"
            style={{ top: 120 }}
          >
            <div className="text-white">
              <h4 className="font-roboto h2 fw-bold">{title}</h4>
              <p>{description}</p>
            </div>

            <div className="d-flex gap-2">
              <Link href={webDownload}>
                <Image
                  src="/assets/gplay.png"
                  alt="google play logo"
                  width={150}
                  height={48}
                  placeholder="blur"
                  blurDataURL="/assets/gplay.png"
                  className="cursor-pointer"
                />
              </Link>

              <Link href={appDownload}>
                <Image
                  src="/app-store.png"
                  alt="app store"
                  width={163}
                  height={48}
                  placeholder="blur"
                  blurDataURL="/app-store.png"
                  className="cursor-pointer"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-none d-md-block position-relative"
        style={{ height: "380px" }}
      >
        <Image
          src="/assets/ads-footer-new-background.png"
          objectFit="contain"
          layout="fill"
        />

        <div className="position-relative container d-flex align-items-start gap-4 h-100">
          <div
            className="position-relative flex-grow-1"
            style={{ height: "350px", top: 38 }}
          >
            <Image
              src="/assets/ads-footer-phone-background.png"
              layout="fill"
            />
          </div>

          <div
            className="position-relative d-flex flex-column justify-content-between gap-2"
            style={{ width: "853px", top: 70 }}
          >
            <div className="text-white">
              <h4 className="font-roboto h2 fw-bold">{title}</h4>
              <p>{description}</p>
            </div>

            <div className="d-flex gap-2">
              <Link href={webDownload}>
                <Image
                  src="/assets/gplay.png"
                  alt="google play logo"
                  width={150}
                  height={48}
                  placeholder="blur"
                  blurDataURL="/assets/gplay.png"
                  className="cursor-pointer"
                />
              </Link>

              <Link href={appDownload}>
                <Image
                  src="/app-store.png"
                  alt="app store"
                  width={163}
                  height={48}
                  placeholder="blur"
                  blurDataURL="/app-store.png"
                  className="cursor-pointer"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterAppAds;
